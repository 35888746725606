import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { Observable } from 'rxjs';
@Component({
  selector: 'app-ktable',
  templateUrl: './ktable.component.html',
  styleUrls: ['./ktable.component.css']
})

export class KtableComponent implements OnInit { 

  @Input()  public expand: boolean;
  @Input()  public datos: any;
  @Output() TablaClick = new EventEmitter<string>();
  @Output() ButtonClick = new EventEmitter<string>();

  public loading = true;
  public rows: Array<any> = [];
  public columns: Array<any> = [];
  public headers: any;
  private data: any[] = [];
  public numPages: Number = 1;
  public page: Number = 1;
  public length: Number = 0;
  public maxSize: Number = 3;
  public itemsPerPage: Number = 10;
  public columnSort:any = '';
  public sort:any = 'asc';
  public filter: Array<any> = [];
  public searchAll: string;
  public titleButton:any;
  


  public constructor() {  

 }
  public ngOnInit():void { }

  public onChangeTable() {

    console.log('Se produjo un cambio en tabla');

    if ( this.obtenerSort() )  {
      this.operacion('loadData');
     }

  }

  public obtenerSort():boolean {

    this.columnSort = this.headers.column;
    this.sort = this.headers.sort;
    this.filter = [];

    for (let i = 0; i < this.columns.length; i++) {

      if ( this.columns[i].sort === 'asc' || this.columns[i].sort === 'desc' ) {
           this.sort = this.columns[i].sort;
           this.columnSort = this.columns[i].name;

         //  console.log( this.columns[i].name + ' -- ' +  this.columns[i].sort );
        }

       if(this.columns[i].filtering && this.columns[i].filtering.filterString !== '') {
  
           let filterItem =  new Cfilter();
           filterItem.nameColumn = this.columns[i].name;
           filterItem.filter = this.columns[i].filtering.filterString;
           this.filter.push(filterItem);
        }
    }

    if (this.searchAll !== '') {
      let filterItem =  new Cfilter();
      filterItem.nameColumn = 'all';
      filterItem.filter = this.searchAll;
      this.filter.push(filterItem);
    }
    console.log(this.filter);
    return true;
  }

  public printTabla() {

    this.headers = this.datos.headers;

    if (typeof this.columns!== 'undefined' && this.columns.length > 0) {
        // console.log('NO Cargo columnas');
  } else {
        /// console.log('Cargo columnas');
          this.columns = this.datos.columns;
    }

    this.data = this.datos.data;

    this.obtenerSort();

        for (let i = 0; i < this.columns.length; i++) {

          if (this.columns[i].name === this.headers.column ) {
              this.columnSort = this.headers.column;
              this.columns[i].sort = this.headers.sort;
          }

          if (this.columns[i].type === 'estado') {
              let c = this.columns[i].name;

              for (let x = 0; x < this.data.length; x++) {
                if (this.data[x][c] === 'true') {
                   this.data[x][c] = '<img src="assets/img/activo.png" alt="Activo" title="Activo" width="44" height="23">';
                } else {
                   this.data[x][c] = '<img src="assets/img/inactivo.png" alt="Inactivo" title="Inactivo" width="44" height="23">';
                }
              }
            }

          if (this.columns[i].type === 'button') {
              let c = this.columns[i].name;
              for (let x = 0; x < this.data.length; x++) {
                  this.data[x][c] = '<a class="btn btn-inverse btn-sm width-80" href="javascript:;" >' +
                  this.columns[i].title
                  + '</a>';
                }
            }
        }

        this.page =  this.headers.page;
        this.itemsPerPage  = this.headers.itemsPerPage;
        this.numPages =  this.headers.itemsTotal / this.headers.itemsPerPage;
        this.length =  this.headers.itemsTotal;
        this.rows = this.data;

       /// this.onChangeTable();
   }


  public onPageChange(page: any = {page: this.page}):any {
         this.page = page;
         this.operacion('loadData');
      }

  public onChangeFilter(filter: any) {
    this.searchAll = filter;
     if (this.obtenerSort() ) {
      this.operacion('loadData');
      }
    }


  public onCellClick(data: any): any {
      //  console.log(data);
       // console.log(data.column.substr(0,3));
        if (data.column.substring(0,3) === 'Btn') {
            this.TablaClick.emit(data);
        }
  }

  public operacion(operacion:any) {

        this.ButtonClick.emit(operacion);
  }


/*
  public changePage(page:any, data:Array<any> = this.data):Array<any> {
    this.numPages = (page.itemsPerPage) ? page.itemsPerPage : this.numPages;
    page = (page.page) ? page.page : page;
    let start = (page - 1) * this.itemsPerPage;
    let end = this.itemsPerPage > -1 ? (start + this.itemsPerPage) : data.length;
    return data.slice(start, end);
  }



  public changeSort(data:any, config:any):any {
    console.log(data);

  }

    let columns = this.config.sorting.columns || [];
    let columnName:string = void 0;
    let sort:string = void 0;

    for (let i = 0; i < columns.length; i++) {
      if (columns[i].sort !== '' && columns[i].sort !== false) {
        columnName = columns[i].name;
        sort = columns[i].sort;
      }
    }

    if (!columnName) {
      return data;
    }

    // simple sorting
    return data.sort((previous:any, current:any) => {

      if (previous[columnName] > current[columnName]) {
        return sort === 'desc' ? -1 : 1;
      } else if (previous[columnName] < current[columnName]) {
        return sort === 'asc' ? -1 : 1;
      }
      return 0;

    });
  }
*/
/*
  public changeFilter(data:any, config:any):any {

    let filteredData:Array<any> = data;
    
    this.columns.forEach((column:any) => {
      if (column.filtering) {
        filteredData = filteredData.filter((item:any) => {
          return item[column.name].match(column.filtering.filterString);
        });
      }
    });

    
    if (!config.filtering) {
      return filteredData;
    }

    
    if (config.filtering.columnName) {
      return filteredData.filter((item:any) =>
        item[config.filtering.columnName].match(this.config.filtering.filterString));
    }

    
    let tempArray:Array<any> = [];
    filteredData.forEach((item:any) => {
      let flag = false;
      this.columns.forEach((column:any) => {
        if (item[column.name].toString().match(this.config.filtering.filterString)) {
          flag = true;
        }
      });
      if (flag) {
        tempArray.push(item);
      }
    });
    
    filteredData = tempArray;

    return filteredData;
  
  }

*/

}


class Cfilter {
  nameColumn: string;
  filter: string;
}
