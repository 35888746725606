import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';


@Injectable()
export class InsertService {


    public get enviromentMantenedor() {
        return environment.urlMantenedor;
    }

    constructor(private _http: HttpClient) {
        console.log('Servicio listo para utilizar!');
    }

    /**
     *Permite insertar formulario de transferencia y guarda ticket auris
     * @param nroTicketAuris 
     * @param nombreCliente 
     * @param rutCliente 
     * @param nombreBancoCliente 
     * @param tipoCuentaCliente 
     * @param nroCuentaCliente 
     * @param correoElectronicoCliente 
     * @param fechaCreacionNroAuris 
     */
    insertTicketFormularioTransAurisBD(token:any ,nombreCliente: any, rutCliente: any, nombreBancoCliente: any, tipoCuentaCliente: any, nroCuentaCliente: any, correoElectronicoCliente: any) {
        return this._http.post(this.enviromentMantenedor + '/hites/devolucion/nc/debito/formulario',
            {
                token:token,
                nombreCliente: nombreCliente,
                rutCliente: rutCliente,
                nombreBancoCliente: nombreBancoCliente,
                tipoCuentaCliente: tipoCuentaCliente,
                nroCuentaCliente: nroCuentaCliente,
                correoElectronicoCliente: correoElectronicoCliente
           
            },
            { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), observe: 'response' });

    }
}