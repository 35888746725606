import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'typeLocalizacion'
})
export class TypeLocalizacionPipe implements PipeTransform {

    transform(value: any): any {

          switch (value) {
            case '': return 'Zona';
            case 'Zona': return 'Region';
            case 'Region': return 'Provincia';
            case 'Provincia' : return 'Comuna';
            case 'Comuna': return 'Localidad';
            case 'Localidad': return '';
  }

}
}
