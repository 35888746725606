import {Component, OnInit, Input, Output, EventEmitter, ViewChild, TemplateRef, Injectable} from '@angular/core';
import {NgbDateAdapter, NgbDateStruct, NgbModalConfig, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {CloneVisitor} from '@angular/compiler/src/i18n/i18n_ast';

import {NgbDatepickerConfig, NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';
import {NgbDateFRParserFormatter} from '../../util/NgbDateFRParserFormatter';

// for ngb datepicker adapter
@Injectable()
export class NgbDateNativeAdapter extends NgbDateAdapter<Date> {

    fromModel(date: Date): NgbDateStruct {
        return (date && date.getFullYear) ? {year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate()} : null;
    }

    toModel(date: NgbDateStruct): Date {
        return date ? new Date(date.year, date.month - 1, date.day) : null;
    }
}

@Component({
    selector: 'app-k-table',
    templateUrl: './k-table.component.html',
    styleUrls: ['./k-table.component.css'],
    providers: [{provide: NgbDateParserFormatter, useClass: NgbDateFRParserFormatter}]
})

export class KTableComponent implements OnInit {

    @Input() public expand: boolean;
    @Input() public datos: any;
    @Input() public panel: boolean = false;
    @Input() public btn_agregar = true;
    @Input() public tree: boolean = false;
    @Input() public treeFromRelation: any;
    @Input() public treeToRelation: any;
    @Input() public columnMode = 'standard';
  @Input()  public appNameComponent: any =  '';
  @Input()  public appDatoComponent: any =  '';
  @Input()  public estiloTabla: any =  'principalTable';
  @Input()   public rowHeight: any = 50;
    @Output() ButtonClick = new EventEmitter<string>();

    @ViewChild('Select', {static: false}) Select: TemplateRef<any>;
    @ViewChild('DateRange', {static: false}) DateRange: TemplateRef<any>;
    @ViewChild('TextInput', {static: false}) TextInput: TemplateRef<any>;
    @ViewChild('DateInput', {static: false}) DateInput: TemplateRef<any>;
    @ViewChild('NumberInput', {static: false}) NumberInput: TemplateRef<any>;
    @ViewChild('DefaultColHead', {static: false}) DefaultColHead: TemplateRef<any>;
    @ViewChild('ButtonTable', {static: false}) ButtonTable: TemplateRef<any>;
    @ViewChild('ModalDateRage', {static: false}) ModalDataRange: TemplateRef<any>;
    @ViewChild('Auxiliar', {static: false}) Auxiliar: TemplateRef<any>;
    @ViewChild('btnTreeView', {static: false}) btnTreeView: TemplateRef<any>;
    @ViewChild('detalleRow', {static: false}) detalleRow: TemplateRef<any>;
    @ViewChild('tablaFull', {static: false}) tablaFull: any;

    public model1: Date;
    public model2: Date;
    public rowsVacio = new Array();
    public rows: any;
    public columns: any;
    public columnsMin: any;
    public headers: any;
    public sorts: any;
    public itemsTotal = 2;
    public itemsPerPage = 10;
    public offset = 0;
    public selecCol: any;
    public filtering: Array<any> = [];
    public body: any;
    public isLoading: Boolean = true;
    public title: String = '';
    public expanded: any = {};
    public nombreApp = 'lista-feriados';
    public resultadoFiltro: any;
    public resultadoFiltroAux: any;

    public mantenerFiltros: any = false;

    constructor(config: NgbModalConfig, private modalService: NgbModal) {
        // customize default values of modals used by this component tree
        config.backdrop = 'static';
        config.keyboard = false;
    }

    openDataRange(content, selecCol) {
        this.modalService.open(content, {size: 'lg', ariaLabelledBy: 'modal-basic-title'});
        this.selecCol = selecCol;
        console.log('this.selecCol');
        console.log(this.selecCol);
    }

    openModal(content) {
        this.modalService.open(content);
    }

    ngOnInit() {
    }

    public expandLista($event: boolean) {
        this.expand = $event;
    }

    public operacion(operacion: any) {
        this.isLoading = true;
        this.ButtonClick.emit(operacion);
    }

    public deepClone(obj: any) {
        let clone = [];
        // tslint:disable-next-line:forin
        for (let key in obj) {
            let value = obj[key];
            if (typeof(value) !== 'object') {
                clone[key] = value;
            } else {
                clone[key] = this.deepClone(value);
            }
        }
        return clone;
    }

    public  formatoDateDia(texto) {
        
    if(texto.length > 22){
        let auxtexto = texto.split('T');
        texto = auxtexto[0];
    }
    let splitDate = texto.split(' ');
    
    let dia  = new Date(texto).getDay();
    let diaSemana = [ 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'];
    return  splitDate[0].replace(/^(\d{4})-(\d{2})-(\d{2})$/g, '$3/$2/$1') + ', ' + diaSemana[dia]; 
    }




    public formatoDate(texto) {
        if(texto.length > 22){
            let auxtexto = texto.split('T');
            texto = auxtexto[0];
        }
      let splitDate = texto.split(' ');
      return   splitDate[0].replace(/^(\d{4})-(\d{2})-(\d{2})$/g, '$3/$2/$1');
    }

    public formatoTime(texto) {
        let splitDate = texto.split(' ');
        let date = this.formatoDate(splitDate[0]);
        let time = splitDate[1];
        return date + ' ' + time;
    }

    public printTabla() {
        this.headers = this.datos.headers;
        this.sorts = this.datos.sorts;
        this.itemsTotal = this.headers.itemsTotal;
        this.itemsPerPage = this.headers.itemsPerPage;
        this.title = this.headers.title;
        this.columnsMin = this.deepClone(this.datos.columns);

        if (this.headers.page - 1 > 0) {
            this.offset = this.headers.page - 1;
        }

        if (this.itemsTotal > 0) {
            for (let i = 0; i < this.datos.columns.length; i++) {
                if (this.columnsMin[0].preview !== true) {
                    this.columnsMin.splice(i, 0);
                }

                /*Definicion de cabeceras de las columnas y filtros */
                if (this.datos.columns[i].filter !== false) {

                    switch (this.datos.columns[i].filterType) {
                        case 'text':
                            this.datos.columns[i].headerTemplate = this.TextInput;
                            break;
                        case 'number':
                            this.datos.columns[i].headerTemplate = this.NumberInput;
                            break;
                        case 'select':
                            this.datos.columns[i].headerTemplate = this.Select;
                            break;
                        case 'daterange':
                            this.datos.columns[i].headerTemplate = this.DateRange;
                            break;
                        case 'date':
                            this.datos.columns[i].headerTemplate = this.DateInput;
                            break;
                        default:
                            this.datos.columns[i].headerTemplate = this.TextInput;
                    }
                } else {
                    this.datos.columns[i].headerTemplate = this.DefaultColHead;
                }

                /* fin cabecera */


                if (this.datos.columns[i].isDetalle === true) {
                    if (this.datos.columns[i].isDetalle === true) {
                        this.datos.columns[i].cellTemplate = this.detalleRow;
                    }
                }


                if (this.datos.columns[i].isTreeColumn === false) {
                    if (this.datos.columns[i].isTreeColumn === false) {
                        this.datos.columns[i].cellTemplate = this.btnTreeView;
                    }
                }


                if (this.datos.columns[i].type === 'auxiliar') {
                    this.datos.columns[i].headerTemplate = this.Auxiliar;
                }


                if (this.datos.columns[i].type === 'fechaHora') {
                    let c = this.datos.columns[i].prop;
                    for (let x = 0; x < this.datos.data.length; x++) {
                        this.datos.data[x][c] = this.formatoTime(this.datos.data[x][c]);
                    }
                }

                if (this.datos.columns[i].type === 'fecha') {
                    let c = this.datos.columns[i].prop;
                    for (let x = 0; x < this.datos.data.length; x++) {
                        this.datos.data[x][c] = this.formatoDate(this.datos.data[x][c]);
                    }
                }

                if (this.datos.columns[i].type === 'fechaNombreDia') {
                    let c = this.datos.columns[i].prop;
                    for (let x = 0; x < this.datos.data.length; x++) {
                        this.datos.data[x][c] = this.formatoDateDia(this.datos.data[x][c]);
                    }
                }
      if (this.datos.columns[i].type === 'fechaDia') {
        let c = this.datos.columns[i].prop;
        for (let x = 0; x < this.datos.data.length; x++) {

                let splitted = this.datos.data[x][c].split('|', 2); 

                if (splitted[1] === 'FE') {
                this.datos.data[x][c] =   this.formatoDateDia(splitted[0]) + '<img class="ml-3" width="18" src="assets/img/feria.png" />';
                }
                
                if (splitted[1] === 'FS') {
                  this.datos.data[x][c] =   this.formatoDateDia(splitted[0]) + '<img class="ml-3" width="16" src="assets/img/finde.png" />';
                  }

                if (splitted[1] === 'S') {
                    this.datos.data[x][c] =   this.formatoDateDia(splitted[0]) ;
                  }
  
              }
      }


                if (this.datos.columns[i].type === 'precio') {
                    let c = this.datos.columns[i].prop;
                    for (let x = 0; x < this.datos.data.length; x++) {
                        this.datos.data[x][c] = '$' + this.number_format(this.datos.data[x][c], 0);
                    }
                }

                if (this.datos.columns[i].type === 'miles') {
                    let c = this.datos.columns[i].prop;
                    for (let x = 0; x < this.datos.data.length; x++) {
                        this.datos.data[x][c] = this.number_format(this.datos.data[x][c], 0);
                    }
                }

                if (this.datos.columns[i].type === 'estado') {
                    let c = this.datos.columns[i].prop;
                    for (let x = 0; x < this.datos.data.length; x++) {
                        if (this.datos.data[x][c] === 1 || this.datos.data[x][c] === 'S') {
                            this.datos.data[x][c] = '  <img src="assets/img/activo.png"  class="imagenK" title="activo" alt="activo">';
                        } else {
                            this.datos.data[x][c] = '  <img src="assets/img/inactivo.png" class="imagenK" title="inactivo" alt="inactivo">';
                        }
                    }
                }

      if (this.datos.columns[i].type === 'semaforo') {
        let c = this.datos.columns[i].prop;
        for (let x = 0; x < this.datos.data.length; x++) {
          if (this.datos.data[x][c] === 0) {
               this.datos.data[x][c] = '  <img src="assets/img/verde.png"  class="imagenK" title="activo" alt="OK">';
            }

          if (this.datos.data[x][c] === 1) {
              this.datos.data[x][c] = '  <img src="assets/img/amarillo.png"  class="imagenK" title="activo" alt="Atención">';
            }

           if (this.datos.data[x][c] === 2) {
            this.datos.data[x][c] = '  <img src="assets/img/rojo.png"  class="imagenK" title="activo" alt="Crítico">';
            }
            if (this.datos.data[x][c] === 3) {
                this.datos.data[x][c] = '  <img src="assets/img/rojo.png"  class="imagenK" title="activo" alt="Muy Crítico">';
            }
          }
        }


      if (this.datos.columns[i].type === 'porcentaje') {
        let c = this.datos.columns[i].prop;
        for (let x = 0; x < this.datos.data.length; x++) {
            let colorB = ' bg-success';
            let porcentaje =  this.datos.data[x][c];

             if (this.datos.data[x][c] > 50 && this.datos.data[x][c] < 80) {
               colorB = ' bg-warning';
             }

             if (this.datos.data[x][c] > 80  && this.datos.data[x][c] < 100 ) {
                 colorB = ' bg-danger';
             }

             if (this.datos.data[x][c] > 100) {
                colorB = ' bg-dark';
                porcentaje =  100;
             }

           this.datos.data[x][c] = '<div class="progress"><div class="progress-bar progress-bar-striped progress-bar-animated' + colorB
                                    + ' por' + porcentaje + '" role="progressbar"> ' + this.datos.data[x][c] + '% </div></div>';

      }
    }

                if (this.datos.columns[i].type === 'ordenUp') {
                    let c = this.datos.columns[i].prop;
                    for (let x = 0; x < this.datos.data.length; x++) {
                        this.datos.data[x][c] = '<img src="assets/img/up.png" class="imagenK" title="Up" alt="Up">';
                    }
                }


                if (this.datos.columns[i].type === 'ordenDown') {
                    let c = this.datos.columns[i].prop;
                    for (let x = 0; x < this.datos.data.length; x++) {
                        this.datos.data[x][c] = '<img src="assets/img/down.png"  class="imagenK" title="Down" alt="Down">';
                    }
                }


                if (this.datos.columns[i].type === 'button') {
                    let c = this.datos.columns[i].prop;
                    for (let x = 0; x < this.datos.data.length; x++) {
                        this.datos.data[x][c] = '<a class="btn btn-inverse btn-sm  rounded-corner" href="javascript:;"  >'
                            + this.datos.columns[i].cellText
                            + '</a>';
                    }
                }

                if (this.datos.columns[i].type === 'button_delete') {
                    let c = this.datos.columns[i].prop;
                    for (let x = 0; x < this.datos.data.length; x++) {
                        this.datos.data[x][c] = '<a class="btn  btn-sm rounded-corner btn-danger" href="javascript:;"  >'
                            + this.datos.columns[i].cellText
                            + '</a>';
                    }
                }
            }

            this.columnsMin.splice(0, 1);
            // console.log(this.datos.data);
            this.rows = this.datos.data;
        } else {
            this.rows = this.rowsVacio;
        }

        if (this.columns === undefined) {
            this.columns = this.datos.columns;
        }

        this.isLoading = false;

        console.log(this.datos);
    }

    setSort() {
        console.log('se hizo click en el botón');
    }


    onSort(info) {
        this.body.sorts[0].prop = info.sorts[0].prop;
        this.body.sorts[0].dir = info.sorts[0].dir;
        this.operacion('loadData');
    }

    setPage(info) {
        console.log(info);
        this.body.headers.page = info.offset + 1;
        this.operacion('loadData');
    }

    filter(column, valor, valorAux?) {
        console.log("FILTER ACTUAL");
        console.log(this.filtering );

        if (this.mantenerFiltros === false){
            this.filtering = [];
        }
        let noexiste = true;
        const filterItem = new Cfilter();

        console.log("FILTER NUEVO");
        console.log(this.filtering );

        filterItem.prop = column.prop;
        filterItem.filter = valor;
        filterItem.filterAux = valorAux;

        console.log("FILTER column");
        console.log(column);

        for (let i = 0; i < this.filtering.length; i++) {
            if (this.filtering[i].prop === column.prop) {
                this.filtering[i] = filterItem;
                noexiste = false;
            }
        }

        if (noexiste) {
            this.filtering.push(filterItem);
        }

        console.log(this.filtering);
        this.body.filtering = this.filtering;

        this.body.headers.page = 1;
        this.operacion('loadData');
        // this.filtering = [];
        // this.model1 = null;
        // this.model2 = null;
    }


    // setFiltering(column, type? ) {
    //     for ( let i = 0; i < this.filtering.length; i++) {
    //       if  (this.filtering[i].prop === column.prop ) {
    //         switch (type) {
    //           case 'date':
    //             return this.filtering[i].filter.year + '/' + this.filtering[i].filter.month + '/' + this.filtering[i].filter.day;
    //           case 'dateAux':
    //             return this.filtering[i].filterAux.year + '/' + this.filtering[i].filterAux.month + '/' + this.filtering[i].filterAux.day;
    //          default:
    //             return this.filtering[i].filter;
    //         }
    //       }
    //     }
    // }

    setFiltering2(column) {
        
        for (let i = 0; i < this.filtering.length; i++) {
            if (this.filtering[i].prop === column.prop) {
                    return   this.filtering[i].filter.day  + '/' +   this.filtering[i].filter.month + '/' + this.filtering[i].filter.year;
                }
            }
    }

    setFiltering(column, type?) {
        for (let i = 0; i < this.filtering.length; i++) {
            if (this.filtering[i].prop === column.prop) {
                if (typeof this.filtering[i].filterAux !== 'undefined' && this.filtering[i].filterAux !== null){
                    switch (type) {
                        case 'date':
                            console.log('FILTER 1');
                            console.log(this.filtering[i].filter);
                            if ((this.filtering[i].filter === 'undefined' || this.filtering[i].filter === null || this.filtering[i].filter === '')
                                && (this.filtering[i].filterAux !== 'undefined' || this.filtering[i].filterAux !== null)) {
                                return this.filtering[i].filter = '';
                            } else if (this.filtering[i].filter !== 'undefined' || this.filtering[i].filter !== null || this.filtering[i].filter !== ''){
              return   this.filtering[i].filter.day  + '/' +   this.filtering[i].filter.month + '/' + this.filtering[i].filter.year;
                            }
                        case 'dateAux':
                            console.log('FILTER 2');
                            console.log(this.filtering[i].filterAux);
              return this.filtering[i].filterAux.day + '/' +   this.filtering[i].filterAux.month + '/' + this.filtering[i].filterAux.year;
                        default:
                            console.log('RETURN FILTER');
                            console.log(this.filtering[i].filter);
                            return this.filtering[i].filter;
                    }
                }
            }
        }
    }

    /* Accion de clicker en una celda. */
    onActivate(event) {

        //console.log(event);
        if (typeof(event) !== 'object') {
            this.ButtonClick.emit(event);
            console.log(event);
        }


        if (event.type === 'click' && event.column.accion !== undefined) {
            this.ButtonClick.emit(event);
            console.log(event);
        }

        if (event.type === 'click' && event.column.prop === 'auxiliar') {
            this.onTreeAction(event);
            console.log(event);
        }

    }

    onTreeAction(event: any) {
        const index = event.rowIndex;
        const row = event.row;
        if (row.treeStatus === 'collapsed') {
            row.treeStatus = 'expanded';
        } else {
            row.treeStatus = 'collapsed';
        }
        this.rows = [...this.rows];
    }


    public number_format(amount, decimals) {

        amount += ''; // por si pasan un numero en vez de un string
        amount = parseFloat(amount.replace(/[^0-9\.]/g, '')); // elimino cualquier cosa que no sea numero o punto

        decimals = decimals || 0; // por si la variable no fue fue pasada

        // si no es un numero o es igual a cero retorno el mismo cero
        if (isNaN(amount) || amount === 0) {
            return parseFloat('0').toFixed(decimals);
        }
        // si es mayor o menor que cero retorno el valor formateado como numero
        amount = '' + amount.toFixed(decimals);

        let amount_parts = amount.split('.'), regexp = /(\d+)(\d{3})/;

        while (regexp.test(amount_parts[0])) {
            amount_parts[0] = amount_parts[0].replace(regexp, '$1' + '.' + '$2');
        }
        return amount_parts.join('.');
    }


    toggleExpandRow(row) {
        console.log('Toggled Expand Row!', row);
        this.tablaFull.rowDetail.toggleExpandRow(row);
    }


    onDetailToggle(event) {
        console.log('Detail Toggled', event);
    }

    close(){
        this.model1 = null;
        this.model2 = null;
    }

    recarga() {
        const filterItem = new Cfilter();

        filterItem.prop = '';
        filterItem.filter = '';
        filterItem.filterAux = '';

        this.filtering.push(filterItem);

        console.log(this.filtering);
        this.body.filtering = this.filtering;

        this.operacion('loadData');

        this.filtering = [];
        this.model1 = null;
        this.model2 = null;
    }
}

class Cfilter {
    prop: string;
    filter: string;
    filterAux?: string;
}

