// Core Module
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { BrowserAnimationsModule }               from '@angular/platform-browser/animations';
import { BrowserModule, Title }                  from '@angular/platform-browser';
import { AppRoutingModule }                      from './app-routing.module';
import { NgbModule }                             from '@ng-bootstrap/ng-bootstrap';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import { FormsModule, ReactiveFormsModule }      from '@angular/forms';
import { MatSortModule, MatTableModule }         from '@angular/material';
import { MatCheckboxModule }         from '@angular/material/checkbox';
import * as global                               from './config/globals';
import { NgProgressModule } from '@ngx-progressbar/core';
import { NgProgressHttpModule } from '@ngx-progressbar/http';
// Main Component
import { AppComponent }                    from './app.component';
import { HeaderComponent }                 from './components/header/header.component';
import { SidebarComponent }                from './components/sidebar/sidebar.component';
import { SidebarRightComponent }           from './components/sidebar-right/sidebar-right.component';
import { TopMenuComponent }                from './components/top-menu/top-menu.component';
import { FooterComponent }                 from './components/footer/footer.component';
import { PanelComponent }                  from './components/panel/panel.component';
import { FloatSubMenuComponent }           from './components/float-sub-menu/float-sub-menu.component';


// Component Module
import { AgmCoreModule }                   from '@agm/core';
import { FullCalendarModule }              from '@fullcalendar/angular';
import { LoadingBarRouterModule }          from '@ngx-loading-bar/router';
import { NgxChartsModule }                 from '@swimlane/ngx-charts';
import { NgxDatatableModule }              from '@swimlane/ngx-datatable';
import { TrendModule }                     from 'ngx-trend';
import { CountdownModule }                 from 'ngx-countdown';
import { ChartsModule }                    from 'ng4-charts/ng4-charts';
import { TagInputModule }                  from 'ngx-chips';
import { SweetAlert2Module }               from '@sweetalert2/ngx-sweetalert2';
import { Ng2TableModule }                  from 'ngx-datatable/ng2-table';
import { NvD3Module }                      from 'ng2-nvd3';
import 'd3';
import 'nvd3';
import { CalendarModule, DateAdapter }     from 'angular-calendar';
import { adapterFactory }                  from 'angular-calendar/date-adapters/date-fns';
import { PerfectScrollbarModule }          from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG }        from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { TreeModule } from 'angular-tree-component';

import { HttpClientModule } from '@angular/common/http';


const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};


// Pages
import { KtableComponent } from './components/ktable/ktable.component';
import { KTableComponent } from './components/k-table/k-table.component';
import { TypeLocalizacionPipe } from './pipe/type-localizacion.pipe';
import {InsertService} from './services/ServiciosInsert/insert.service';
import { GetAllService } from './services/ServiciosGetAll/get.all.service';
import {AppInitService} from './app.init.service';
import {Ng4LoadingSpinnerModule} from 'ng4-loading-spinner';
import {DatePipe} from '@angular/common';
import { AgregarTicketFormularioCorreoComponent } from './components/auris-ticket/agregar-ticket-formulario-correo/agregar-ticket-formulario-correo.component';

export function init_app(appLoadService: AppInitService) {
    return () => appLoadService.init();
}

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        SidebarComponent,
        SidebarRightComponent,
        TopMenuComponent,
        FooterComponent,
        PanelComponent,
        FloatSubMenuComponent,
        KtableComponent,
        TypeLocalizacionPipe,
        KTableComponent,
    AgregarTicketFormularioCorreoComponent,
    ],
  imports: [
    AppRoutingModule,
    AgmCoreModule.forRoot({ apiKey: 'AIzaSyC5gJ5x8Yw7qP_DqvNq3IdZi2WUSiDjskk' }),
    BrowserAnimationsModule,
    BrowserModule,
    Ng4LoadingSpinnerModule.forRoot(),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    CountdownModule,
    ChartsModule,
    FullCalendarModule,
    FormsModule,
    LoadingBarRouterModule,
    MatSortModule,
    MatTableModule,
    MatCheckboxModule,
    NgbModule,
    NvD3Module,
    NgxChartsModule,
    NgxDatatableModule,
    Ng2TableModule,
    PerfectScrollbarModule,
    ReactiveFormsModule,
    SweetAlert2Module.forRoot(),
    TagInputModule,
    TrendModule,
    HttpClientModule,
    TreeModule.forRoot(),
    NgProgressModule,
    NgProgressHttpModule,
  ],
  providers: [ Title, {
    provide: PERFECT_SCROLLBAR_CONFIG,
    useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
  },  
      InsertService,
      AppInitService,
      GetAllService,
      DatePipe,
      {
          provide: APP_INITIALIZER,
          useFactory: init_app,
          deps: [AppInitService],
          multi: true
      }],
  bootstrap: [ AppComponent ]
})

export class AppModule {
  constructor(private router: Router, private titleService: Title, private route: ActivatedRoute) {
    router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        var title = this.route.snapshot.firstChild.data['title'];
        this.titleService.setTitle(title);
      }
    });
  }
}
