import {
    Component,
    ViewChild,
    AfterViewInit,
    Input, OnDestroy, Output
} from '@angular/core';

import { KTableComponent } from '../../k-table/k-table.component';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { InsertService } from '../../../services/ServiciosInsert/insert.service';
import { DatePipe } from '@angular/common';
import { EventEmitter } from 'events';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { GetAllService } from '../../../services/ServiciosGetAll/get.all.service';
import { environment } from '../../../../environments/environment';
@Component({
    selector: 'app-agregar-ticket-formulario-correo',
    templateUrl: './agregar-ticket-formulario-correo.component.html',
    styleUrls: ['./agregar-ticket-formulario-correo.component.css']
})

export class AgregarTicketFormularioCorreoComponent implements AfterViewInit, OnDestroy {


    @Input() public expand: boolean;
    @Input() public panel: boolean = false;
    @ViewChild(KTableComponent, { static: false }) ktable: KTableComponent;
    public session = localStorage;
    public modal: NgbModalRef;

    public ngOnDestroy(): void {
    }

    public ngAfterViewInit(): void {
    }

    @Input() public nodes: any;
    @Output() elClick = new EventEmitter();

    public datos: any;
    public bordeRojo: boolean = false;
    public selectTipoDireccion: any;
    public selecResultado: any;
    public selectMotivo: any;
    public selectDetalle: any;
    public selectBanco: any;
    public selectCodigoBanco: any;
    public selectTipoCuenta: any;
    public urlFormularioToken: any;
    public selectBancoBD: any;
    public selectTipoCuentaBanco: any;
    public selectElementBanco: any;
    public selectElementTipoCuentaBanco: any;

    /** 
     * Objetos Datos base de datos
     */
    public nroTicketAuris: any;
    public nombreCliente: any;
    public rutCliente: any;
    public nombreBancoCliente: any;
    public tipoCuentaCliente: any;
    public nroCuentaCliente: any;
    public correoElectronicoCliente: any;
    public tokenFormulario: any;
    public habilitadoObj: boolean = false;
    public validaAccion: boolean = true;


    constructor(
        private insert: InsertService,
        private spinnerService: Ng4LoadingSpinnerService,
        private datePipe: DatePipe,
        private modalService: NgbModal,
        private router: Router,
        public getAll: GetAllService

    ) { }

    public get enviromentPagina() {
        return environment.urlPaginaNC;
    }

    /**
     * Valida estado del token para habilitar o deshabilitar formulario 
     * dependiendo del estado del token
     */
    ngOnInit() {
        this.habilitadoObj = true;
        if (this.validaAccion === true) {
            this.urlFormularioToken = this.router.url;
            let largoToken: number = this.urlFormularioToken.length;
            this.tokenFormulario = this.urlFormularioToken.substring(23, largoToken);
            this.getAll.getToken(this.tokenFormulario)
                .subscribe((data3: HttpResponse<any>) => {
                    if (data3.body.codigo != "0") {
                        Swal.fire('Error al generar la solicitud de devolución:', data3.body.mensaje, 'error').then((result) => {
                            this.habilitadoObj = false;
                            window.close();
                        });
                    }
                    if (data3.body.codigo === '00026') {
                        Swal.fire('Error', 'No fue posible realizar la solicitud, acceso inválido, no existe en nuestros registros', 'error');
                        this.habilitadoObj = false;
                        window.close();
                    }
                    if (data3.body.codigo === '5') {
                        Swal.fire('Error', 'No fue posible realizar la solicitud', 'error');
                        this.habilitadoObj = false;
                        window.close();
                    }
                });
        }
        this.validaAccion = false;
        this.nombreBancoCliente = null;
        this.tipoCuentaCliente = null;
        this.nroTicketAuris = '';
        this.nombreCliente = '';
        this.rutCliente = '';
        this.nroCuentaCliente = '';
        this.correoElectronicoCliente = '';

        /**
         * Obtenemos  listado de bancos
         * @param id 
         * @param name 
         */
        this.getAll.getAllBancos()
            .subscribe((data5: HttpResponse<any>) => {
                this.selectBancoBD = data5.body.bancos;
            });
    }

    /**
     * Obtenemos  valor del combo banco
     * @param id 
     * @param name 
     */
    public obtenerComboBanco(id: any, name: any) {
        let selectElementText = name.target['options']
        [event.target['options'].selectedIndex].text;
        this.selectElementBanco = selectElementText;

        /**
         * Tipo cuenta
        */
        this.getAll.getAllTipoCuenta(id)
            .subscribe((data5: HttpResponse<any>) => {
                this.selectTipoCuentaBanco = data5.body.body;
            });

    }

    /**
     * Obtiene valor tipo cuenta
     * @param value 
     */
    obtenerComboTipoCuenta(value: any) {
        this.selectElementTipoCuentaBanco = value;
    }

    public open(data: any) {
        this.datos = data;
    }

    /**
     * Transforma fecha
     * @param formato 
     * @returns 
     */
    transformDateNow(formato) {
        let date = new Date();
        return this.datePipe.transform(date, formato);
    }

    /**
     * Registrar ticket Auris en base de datos
     */
    public guardarTicketFormularioTransAurisBD() {
        this.spinnerService.show();
        this.nombreBancoCliente = this.selectElementBanco;
        this.tipoCuentaCliente = this.selectElementTipoCuentaBanco;
        this.insert.insertTicketFormularioTransAurisBD(this.tokenFormulario, this.nombreCliente, this.rutCliente, this.nombreBancoCliente, this.tipoCuentaCliente, this.nroCuentaCliente, this.correoElectronicoCliente)
            .subscribe((data: HttpResponse<any>) => {
                this.spinnerService.hide();
                if (null != data.body.body && data.body.codigo == '0') {
                    Swal.fire('Número de ticket generado:' + ' ' + data.body.body, '', 'success').then((result) => {
                        this.correoElectronicoCliente = '';
                        this.tipoCuentaCliente = '';
                        this.nroCuentaCliente = '';
                        this.nombreBancoCliente = null;
                        this.tipoCuentaCliente = null;
                        this.rutCliente = '';
                        this.nombreCliente = '';
                        this.correoElectronicoCliente = '';
                        window.close();
                    });
                }
                if (data.body.codigo === '00012') {
                    Swal.fire('Error', 'Correo electrónico inválido', 'error');
                }
                if (data.body.codigo === '00013') {
                    Swal.fire('Error', 'Debe ingresar tipo de cuenta', 'error');
                }
                if (data.body.codigo === '00014') {
                    Swal.fire('Error', 'Debe ingresar banco', 'error');
                }
                if (data.body.codigo === '00015') {
                    Swal.fire('Error', 'Debe ingresar número de cuenta', 'error');
                }
                if (data.body.codigo === '00016') {
                    Swal.fire('Error', 'Debe ingresar nombre', 'error');
                }
                if (data.body.codigo === '00017') {
                    Swal.fire('Error', 'Rut inválido', 'error');
                }
                if (data.body.codigo === '00018') {
                    Swal.fire('Error', 'Debe ingresar Rut (sin guión pero con dígito verificador Ej: 123456780)', 'error');
                }
                if (data.body.codigo === '00019') {
                    Swal.fire('Error', 'Debe enviar el token asociado a la solicitud', 'error');
                }
                if (data.body.codigo === '00020') {
                    Swal.fire('Error', 'La solicitud ya se encuentra en proceso', 'error');
                }
                if (data.body.codigo === '00021') {
                    Swal.fire('Error', 'La solicitud ya fue ingresada', 'error');
                }
                if (data.body.codigo === '00022') {
                    Swal.fire('Error', 'Token de la solicitud expirado', 'error');
                }
                if (data.body.codigo === '00023') {
                    Swal.fire('Error', 'No tiene solicitud asociada al token', 'error');
                }
                if (data.body.codigo === '00024') {
                    Swal.fire('Error', 'El Rut de depósito no coincide con cliente hites', 'error');
                }
                if (data.body.codigo === '00025') {
                    Swal.fire('Error', 'No fue posible realizar la solicitud con Auris', 'error');
                }
                if (data.body.codigo === '5') {
                    Swal.fire('Error', 'No fue posible realizar la solicitud', 'error');
                }
            });
    }
}