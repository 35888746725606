import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';
import { AgregarTicketFormularioCorreoComponent } from './components/auris-ticket/agregar-ticket-formulario-correo/agregar-ticket-formulario-correo.component';



const routes: Routes = [
  { path: '', redirectTo: '/formularioAuris', pathMatch: 'full' },
  { path: 'formularioAuris', component: AgregarTicketFormularioCorreoComponent, data: { title: 'Formulario de Devolución' } },
];

@NgModule({
  imports: [CommonModule, RouterModule.forRoot(routes)],
  exports: [RouterModule],
  declarations: []
})


export class AppRoutingModule { }
