import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs/Observable';

@Injectable()
export class GetAllService {



    public get enviromentMantenedor() {
        return environment.urlMantenedor;
    }

    constructor(private _http: HttpClient) {
        console.log('Servicio listo para utilizar!');
    }

    // Obtiene estado del token
    getToken(tokenEstado: any) {
        return this._http.post(this.enviromentMantenedor + '/hites/devolucion/nc/debito/recuperarToken',

            { token: tokenEstado },

            { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), observe: 'response' });
    }
    // Obtiene listado de bancos
    getAllBancos() {
        return this._http.post(this.enviromentMantenedor + '/hites/devolucion/nc/debito/obtener',
            { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), observe: 'response' });
    }
    // Obtiene listado de tipo de cuenta banco
    getAllTipoCuenta(id: any) {
        return this._http.post(this.enviromentMantenedor + '/hites/devolucion/nc/debito/tipoCuenta',
            {
                idBanco: id
            },
            { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), observe: 'response' });
    }
}
